import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path1/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "stateselector"
    }}>{`StateSelector`}</h1>
    <p>{`在多个组件间共享 `}<strong parentName="p">{`EffectModule`}</strong>{` 时，通常会需要从整个 `}<strong parentName="p">{`AppState`}</strong>{` 上选择一部分出来给组件使用从而避免无效渲染。
`}<strong parentName="p">{`Sigi`}</strong>{` 在 `}<inlineCode parentName="p">{`useModule`}</inlineCode>{` 和 `}<inlineCode parentName="p">{`useModuleState`}</inlineCode>{` hook 中提供了 `}<strong parentName="p">{`selector`}</strong>{` API 来实现这个需求。组件可以从 `}<strong parentName="p">{`EffectModule`}</strong>{` 对应的 `}<strong parentName="p">{`AppState`}</strong>{` 上选择自己关心的那部分状态，`}<strong parentName="p">{`AppState`}</strong>{` 上其它部分的更新会被该组件忽略掉，也不会引起该组件的渲染。`}</p>
    <iframe src="https://codesandbox.io/embed/sigi-recipes-state-selector-m4m18?expanddevtools=1&fontsize=14&hidenavigation=1&module=%2Fsrc%2Findex.tsx&theme=dark" style={{
      "width": "100%",
      "height": "500px",
      "border": "0",
      "borderRadius": "4px",
      "overflow": "hidden"
    }} title="sigi-recipes-state-selector" allow="geolocation; microphone; camera; midi; vr; accelerometer; gyroscope; payment; ambient-light-sensor; encrypted-media; usb" sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin"></iframe>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      